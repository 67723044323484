.bottom-5 {
  bottom: 5%;
}
.right-7p5 {
  right: 7.5%;
}
.w-4vw {
  width: 8vw;
}
.h-4vw {
  height: 8vw;
}
.z-index-2500 {
  z-index: 2500;
}
