/* CSS - Cascading Style Sheet */
/* Palette color codes */
/* Palette URL: http://paletton.com/#uid=13p0u0kex8W2uqu8af7lEqaulDE */

/* Feel free to copy&paste color codes to your application */

/* As hex codes */
.color-primary-0 {
	color: #ffffff
}

/* Main Primary color */
.color-primary-1 {
	color: #7A8184
}

.color-primary-2 {
	color: #39404b
}

.color-primary-3 {
	color: #2d4f82
}

.color-primary-4 {
	color: #1067b9
}

/* As RGBa codes */
.rgba-primary-0 {
	color: rgb(0, 0, 0)
}

/* Main Primary color */
.rgba-primary-1 {
	color: rgba(122, 129, 132)
}

.rgba-primary-2 {
	color: rgba(57, 64, 75)
}

.rgba-primary-3 {
	color: rgba(45, 79, 130)
}

.rgba-primary-4 {
	color: rgba(16, 103, 185)
}

/* Generated by Paletton.com Â© 2002-2014 */
/* http://paletton.com */




:root {

	--color-0: rgb(255, 255, 255);
	--color-1: #ccc;
	--color-2: rgba(57, 64, 75);
	--color-3: rgba(45, 79, 130);
	--color-4: rgba(16, 103, 185);

	--bg-color: var(--color-0);
	--bg-color-2: rgb(60, 80, 85);
	--bg-light-color: rgba(48, 61, 65, 1);
	--bg-dark-color: rgba(24, 31, 33, 1);
	--bg-hover-color: var(--color-2);

	--font-color: #000000;
	--font-color-2: #000000;
	--font-color: #000000;
	--border-color: black;

	--measurement-detail-node-bg-light: var(--color-1);
	--measurement-detail-node-bg-dark: var(--color-2);
	--measurement-detail-area-bg-color: #eee;


}

#potree_sidebar_container {
	
	width: 350px;
	height: 100%;
	overflow-y: scroll;
	font-size: 85%;
	border-right: 1px solid black;
	background-color: var(--bg-color);
}

#potree_sidebar_container span {
    color: rgb(0, 0, 0) !important;
}

#sidebar_root {
	color: var(--font-color);
	font-family: Arial, Helvetica, sans-serif;
	font-size: 1em;
	
}

.potree_failpage {
	width: 100%;
	height: 100%;
	background-color: white;
	position: absolute;
	margin: 15px;
}

.potree_failpage a {
	color: initial !important;
	text-decoration: underline !important;
}

.potree_info_text {
	color: rgb(0, 0, 0);
	font-weight: bold;
	text-shadow: 1px 1px 1px black,
		1px -1px 1px black,
		-1px 1px 1px black,
		-1px -1px 1px black;
}

.potree_message {
	width: 500px;
	background-color: var(--bg-color);
	padding: 5px;
	margin: 5px;
	border-radius: 4px;
	color: var(--font-color);
	font-family: Arial;
	opacity: 0.8;
	border: 1px solid black;
	display: flex;
	overflow: auto;
}

.potree_message_error {
	background-color: red;
}

#potree_description {
	position: absolute;
	top: 10px;
	left: 50%;
	transform: translateX(-50%);
	text-align: center;
	z-index: 1000;
}

.potree_sidebar_brand {
	margin: 1px 20px;
	line-height: 2em;
	font-size: 100%;
	font-weight: bold;
	position: relative;
	display: flex;
	flex-direction: row;
}

/* #potree_sidebar_container a{
	color: 			#8Aa1c4;
} */

#potree_quick_buttons {
	position: absolute;
	left: 4px;
	top: 4px;
	width: 10px;
	height: 10px;
	z-index: 10000;
	float: left;
}

.potree_menu_toggle {
	display: none;
	float: left;
	margin: 0;
	width: 2.5em;
	height: 2.5em;
	z-index: 100;
	cursor: pointer;
	margin: 4px;
}

#potree_map_toggle {
	float: left;
	margin: 0;
	background: none;
	width: 2.5em;
	height: 2.5em;
	z-index: 100;
	cursor: pointer;
	margin: 4px;
}

#potree_render_area {
	position: absolute;
	/*background: linear-gradient(-90deg, red, yellow);*/
	top: 0px;
	bottom: 0px;
	left: 0px;
	right: 0px;
	overflow: hidden;
	z-index: 1;
	-webkit-transition: left .35s;
	transition: left .35s;
}

.potree-panel {
	border: 1px solid black;
	border-radius: 0.4em;
	padding: 0px;
	background-color: var(--bg-light-color);
}

.potree-panel-heading {
	background-color: var(--bg-dark-color);
}

a:hover, a:visited, a:link, a:active {
	color: #000000;
	text-decoration: none;
}

.annotation {
	position: absolute;
	padding: 10px;
	opacity: 0.5;
	transform: translate(-50%, -30px);
	will-change: left, top;
}

.annotation-titlebar {
	color: white;
	background-color: black;
	border-radius: 1.5em;
	border: 1px solid rgba(255, 255, 255, 0.7);
	font-size: 1em;
	opacity: 1;
	margin: auto;
	display: table;
	padding: 1px 8px;
	cursor: pointer;
}

.annotation-expand {
	color: white;
	font-size: 0.6em;
	opacity: 1;
}

.annotation-action-icon {
	width: 20px;
	height: 20px;
	display: inline-block;
	vertical-align: middle;
	line-height: 1.5em;
	text-align: center;
	font-family: Arial;
	font-weight: bold;
	cursor: pointer;
}

.annotation-action-icon:hover {
	filter: drop-shadow(0px 0px 1px white);
	width: 24px;
	height: 24px;
	cursor: pointer;

}

.annotation-item {
	color: white;
	background-color: black;
	opacity: 0.5;
	border-radius: 1.5em;
	font-size: 1em;
	line-height: 1.5em;
	padding: 1px 8px 0px 8px;
	font-weight: bold;
	display: flex;
	cursor: default;
}

.annotation-item:hover {
	opacity: 1.0;
	box-shadow: 0 0 5px #ffffff;
}

.annotation-main {
	display: flex;
	flex-grow: 1;
}

.annotation-label {
	display: inline-block;
	height: 100%;
	flex-grow: 1;
	user-select: none;
	-moz-user-select: none;
	z-index: 100;
	vertical-align: middle;
	line-height: 1.5em;
	font-family: Arial;
	font-weight: bold;
	cursor: pointer;
	white-space: nowrap;
}

.annotation-description {
	position: relative;
	color: white;
	background-color: black;
	padding: 10px;
	margin: 5px 0px 0px 0px;
	border-radius: 4px;
	display: none;
	max-width: 500px;
	width: 500px;
}

.annotation-description-close {
	filter: invert(100%);
	float: right;
	opacity: 0.5;
	margin: 0px 0px 8px 8px;
}


.annotation-description-content {
	color: white;
}

.annotation-icon {
	width: 20px;
	height: 20px;
	filter: invert(100%);
	margin: 2px 2px;
	opacity: 0.5;
}


canvas {
	width: 100%;
	height: 100%
}

body {
	margin: 0;
	padding: 0;
	position: absolute;
	width: 100%;
	height: 100%;
	overflow: hidden;
}

.axis {
	font: 10px sans-serif;
	color: var(--font-color);
}

.axis path {
	fill: rgba(255, 255, 255, 0.5);
	stroke: var(--font-color);
	shape-rendering: crispEdges;
	opacity: 0.7;
}

.axis line {
	fill: rgba(255, 255, 255, 0.5);
	stroke: var(--font-color);
	shape-rendering: crispEdges;
	opacity: 0.1;
}

.tick text {
	font-size: 12px;
}

.scene_header {
	display: flex;
	cursor: pointer;
	padding: 2px;
}

.scene_content {
	padding: 5px 0px 5px 0px;
	/*background-color: rgba(0, 0, 0, 0.4);*/
}

.measurement_content {
	padding: 5px 15px 5px 10px;
	/*background-color: rgba(0, 0, 0, 0.4);*/
}

.propertypanel_content {
	padding: 5px 15px 5px 10px;
	/*background-color: rgba(0, 0, 0, 0.4);*/
}

.measurement_value_table {
	width: 100%;
}

.coordinates_table_container table td {
	width: 33%;
	text-align: center;
}

#scene_object_properties {
	margin: 0px;
}




.pv-panel-heading {
	padding: 4px !important;
	display: flex;
	flex-direction: row
}

.pv-menu-list {

	list-style-type: none;
	padding: 0;
	margin: 15px 0px;
	overflow: hidden;
}

.pv-menu-list>* {
	margin: 4px 20px;
}


.ui-slider {
	margin-top: 5px !important;
	margin-bottom: 10px !important;
	background-color: var(--color-1) !important;
	
    
    height: 10px !important;
    
    
    
    border-radius: 5px !important;
}

.ui-slider::-webkit-slider-thumb {
   
    width: 20px !important;
    height: 20px !important;
    background: #4CAF50 !important;
    cursor: pointer !important;
    border-radius: 50% !important;
}

.ui-slider .ui-slider-handle {
    width: 20px !important;
    height: 20px !important;
    background-color: #03016d !important;
    border: 2px solid #03016d !important;
    cursor: pointer !important;
}


/* .ui-selectmenu-button.ui-button {
	width: 100% !important;
} */

/* .pv-menu-list>li>.ui-slider {
	/* background-color: var(--color-1) !important;
	background: none;
	border: 1px solid black;
} */

/* .pv-menu-list .ui-slider {
	/* background-color: var(--color-1) !important; 
	background: none;
	border: 1px solid black !important;
} */

/* .ui-slider-handle {
	border: 1px solid black !important;
} */

/* .ui-widget {
	box-sizing: border-box
} */

/* .panel-body>li>.ui-slider {
	background-color: var(--color-1) !important;
	background: none;
	border: 1px solid black;
} */

/* .panel-body>div>li>.ui-slider {
	background-color: var(--color-1) !important;
	background: none;
	border: 1px solid black;
} */

.pv-select-label {
	margin: 1px;
	font-size: 90%;
	font-weight: 100;
}

.button-icon:hover {
	/*background-color:	#09181C;*/
	filter: drop-shadow(0px 0px 4px white);
}

/* .ui-widget-content{
	color: var(--font-color) !important;
} */

.accordion>h3 {
	background-color: var(--bg-color-2) !important;
	background: #f6f6f6 50% 50% repeat-x;
	border: 1px solid black;
	color: var(--font-color-2);
	cursor: pointer;
	margin: 2px 0 0 0;
	padding: 4px 10px 4px 30px;
	box-shadow: 0px 3px 3px #111;
	text-shadow: 1px 1px 0 #000, -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
	font-size: 1em;
}

.accordion>h3:hover {
	filter: brightness(125%);
}

.accordion-content {
	padding: 0px 0px !important;
	border: none !important;
}

.icon-bar {
	height: 4px !important;
	border: 1px solid black;
	background-color: white;
	border-radius: 2px;
}

.canvas {
	-webkit-transition: top .35s, left .35s, bottom .35s, right .35s, width .35s;
	transition: top .35s, left .35s, bottom .35s, right .35s, width .35s;
}

#profile_window {
	background-color: var(--bg-color);
}

#profile_titlebar {
	background-color: var(--bg-color-2);
	color: var(--font-color-2);
	text-shadow: 1px 1px 0 #000, -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
	font-size: 1em;
	font-weight: bold;
}

#profile_window_title {
	position: absolute;
	margin: 5px;
}

.profile-container-button {
	cursor: pointer;
}

.profile-button:hover {
	background-color: #0000CC;
}

.unselectable {
	user-select: none;
}

.selectable {
	user-select: text;
}

.ol-dragbox {
	background-color: rgba(255, 255, 255, 0.4);
	border-color: rgba(100, 150, 0, 1);
	border: 1px solid red;
}

.text-icon {
	opacity: 0.5;
	height: 24px;
}

.text-icon:hover {
	opacity: 1.0;
}

.input-grid-cell {
	flex-grow: 1;
	margin: 0px 3px 0px 3px;
}

.input-grid-label {
	flex-grow: 1;
	margin: 0px 3px 0px 3px;
	text-align: center;
	font-weight: bold;
}

.input-grid-cell>input {
	width: 100%
}

.invalid_value {
	color: #e05e5e;
}


/** 
 * OVERRIDES
 */


.ui-spinner-input {
	color: black;
}

.jstree-themeicon-custom {
	background-size: 16px !important;
}

.jstree-default .jstree-clicked {
	/*background-color: #ffffff !important;*/
	background-color: #34494f !important;
}

.jstree-default .jstree-hovered {
	background-color: #34494f !important;
}

.jstree-anchor {
	width: 100% !important;
}

.ui-state-default {
	background: #a6a9aa !important;
	border: 1px solid black;
	color: black;
}

.ui-state-active {
	background: #c6c9ca !important;
	color: black !important;
}

.cesium-viewer .cesium-viewer-cesiumWidgetContainer {
	position: absolute;
	height: 100%;
	width: 100%;
}




.zs_widget {
	padding: 2px;
	height: 4em;
	user-select: none;
}

.zs_core {
	overflow: hidden;
	position: relative;
	height: 100%;
}

.zs_handle {
	position: absolute;
	top: 0px;
	bottom: 0px;
	border: 1px solid black;
	border-radius: 3px;
	background-color: rgb(166, 169, 170);
	width: 8px;
	user-select: none;
	width: 1.2em;
	height: 1.2em;
	top: calc(50% - 0.6em);
}

.zs_stretch {
	position: absolute;
	top: 0px;
	bottom: 0px;
	border: 1px solid black;
	border-radius: 3px;
	background-color: rgb(166, 169, 170);
	width: 8px;
	user-select: none;
	width: 1.2em;
	height: 1.2em;
	top: calc(50% - 0.6em);
	color: black;
	font-weight: bold;
	font-size: 1.2em;
	font-family: arial;
}

.zs_handle:hover {
	background-color: lightgreen;
}

.zs_inside {
	position: absolute !important;
	width: 100%;
	border: 1px solid black;
	background-color: white;
	top: calc(50% - 0.326em);
	height: 0.652em;
	cursor: zoom-in;
}

.zs_outside {
	position: absolute !important;
	width: 100%;
	background-color: var(--color-1) !important;
	top: calc(50% - 0.326em);
	height: 0.652em;
	cursor: zoom-in;
}

.zs_visible_range_label {
	position: absolute;
	bottom: 0px;
	pointer-events: none;
}

.zs_visible_range_label_left {
	left: 0px;
}

.zs_visible_range_label_right {
	right: 0px;
}

.zs_chosen_range_label {
	position: absolute;
	pointer-events: none;
}

/* #potree_sidebar_container{
	scrollbar-color: var(--color-1) var(--bg-color);
	scrollbar-width: thin;
} */



::-webkit-scrollbar {
	width: 6px;
	background-color: var(--bg-color);
}

/* ::-webkit-scrollbar-track {} */

::-webkit-scrollbar-thumb {
	background-color: var(--color-1);
}

.propertypanel_content .heading {
	font-weight: bold;
	padding-top: 0.6em;
	padding-bottom: 0.1em;
}





/* Mudanças da Drone Solucions */

/* CSS - Cascading Style Sheet */
/* Palette color codes */
/* Palette URL: http://paletton.com/#uid=13p0u0kex8W2uqu8af7lEqaulDE */

/* Feel free to copy&paste color codes to your application */

/* As hex codes */
.color-primary-0 {
	color: #ffffff
}

/* Main Primary color */
.color-primary-1 {
	color: #7A8184
}

.color-primary-2 {
	color: #39404b
}

.color-primary-3 {
	color: #2d4f82
}

.color-primary-4 {
	color: #1067b9
}

/* As RGBa codes */
.rgba-primary-0 {
	color: rgb(0, 0, 0)
}

/* Main Primary color */
.rgba-primary-1 {
	color: rgba(122, 129, 132)
}

.rgba-primary-2 {
	color: rgba(57, 64, 75)
}

.rgba-primary-3 {
	color: rgba(45, 79, 130)
}

.rgba-primary-4 {
	color: rgba(16, 103, 185)
}

/* Generated by Paletton.com Â© 2002-2014 */
/* http://paletton.com */




:root {

	--color-0: rgb(255, 255, 255);
	--color-1: #ccc;
	--color-2: rgba(57, 64, 75);
	--color-3: rgba(45, 79, 130);
	--color-4: rgba(16, 103, 185);

	--bg-color: var(--color-0);
	--bg-color-2: rgb(60, 80, 85);
	--bg-light-color: rgba(48, 61, 65, 1);
	--bg-dark-color: rgba(24, 31, 33, 1);
	--bg-hover-color: var(--color-2);

	--font-color: #000000;
	--font-color-2: #000000;
	--font-color: #000000;
	--border-color: black;

	--measurement-detail-node-bg-light: var(--color-1);
	--measurement-detail-node-bg-dark: var(--color-2);
	--measurement-detail-area-bg-color: #eee;


}

#potree_sidebar_container {
	
	width: 350px;
	height: 100%;
	overflow-y: scroll;
	font-size: 85%;
	border-right: 1px solid black;
	background-color: var(--bg-color);
}

#potree_sidebar_container span {
    color: rgb(0, 0, 0) !important;
}

#sidebar_root {
	color: var(--font-color);
	font-family: Arial, Helvetica, sans-serif;
	font-size: 1em;
	
}

.potree_failpage {
	width: 100%;
	height: 100%;
	background-color: white;
	position: absolute;
	margin: 15px;
}

.potree_failpage a {
	color: initial !important;
	text-decoration: underline !important;
}

.potree_info_text {
	color: rgb(0, 0, 0);
	font-weight: bold;
	text-shadow: 1px 1px 1px black,
		1px -1px 1px black,
		-1px 1px 1px black,
		-1px -1px 1px black;
}

.potree_message {
	width: 500px;
	background-color: var(--bg-color);
	padding: 5px;
	margin: 5px;
	border-radius: 4px;
	color: var(--font-color);
	font-family: Arial;
	opacity: 0.8;
	border: 1px solid black;
	display: flex;
	overflow: auto;
}

.potree_message_error {
	background-color: red;
}

#potree_description {
	position: absolute;
	top: 10px;
	left: 50%;
	transform: translateX(-50%);
	text-align: center;
	z-index: 1000;
}

.potree_sidebar_brand {
	margin: 1px 20px;
	line-height: 2em;
	font-size: 100%;
	font-weight: bold;
	position: relative;
	display: flex;
	flex-direction: row;
}

/* #potree_sidebar_container a{
	color: 			#8Aa1c4;
} */

#potree_quick_buttons {
	position: absolute;
	left: 4px;
	top: 4px;
	width: 10px;
	height: 10px;
	z-index: 10000;
	float: left;
}

.potree_menu_toggle {
	display: none;
	float: left;
	margin: 0;
	width: 2.5em;
	height: 2.5em;
	z-index: 100;
	cursor: pointer;
	margin: 4px;
}

#potree_map_toggle {
	float: left;
	margin: 0;
	background: none;
	width: 2.5em;
	height: 2.5em;
	z-index: 100;
	cursor: pointer;
	margin: 4px;
}

#potree_render_area {
	position: absolute;
	/*background: linear-gradient(-90deg, red, yellow);*/
	top: 0px;
	bottom: 0px;
	left: 0px;
	right: 0px;
	overflow: hidden;
	z-index: 1;
	-webkit-transition: left .35s;
	transition: left .35s;
}

.potree-panel {
	border: 1px solid black;
	border-radius: 0.4em;
	padding: 0px;
	background-color: var(--bg-light-color);
}

.potree-panel-heading {
	background-color: var(--bg-dark-color);
}

a:hover, a:visited, a:link, a:active {
	color: #000000;
	text-decoration: none;
}

.annotation {
	position: absolute;
	padding: 10px;
	opacity: 0.5;
	transform: translate(-50%, -30px);
	will-change: left, top;
}

.annotation-titlebar {
	color: white;
	background-color: black;
	border-radius: 1.5em;
	border: 1px solid rgba(255, 255, 255, 0.7);
	font-size: 1em;
	opacity: 1;
	margin: auto;
	display: table;
	padding: 1px 8px;
	cursor: pointer;
}

.annotation-expand {
	color: white;
	font-size: 0.6em;
	opacity: 1;
}

.annotation-action-icon {
	width: 20px;
	height: 20px;
	display: inline-block;
	vertical-align: middle;
	line-height: 1.5em;
	text-align: center;
	font-family: Arial;
	font-weight: bold;
	cursor: pointer;
}

.annotation-action-icon:hover {
	filter: drop-shadow(0px 0px 1px white);
	width: 24px;
	height: 24px;
	cursor: pointer;

}

.annotation-item {
	color: white;
	background-color: black;
	opacity: 0.5;
	border-radius: 1.5em;
	font-size: 1em;
	line-height: 1.5em;
	padding: 1px 8px 0px 8px;
	font-weight: bold;
	display: flex;
	cursor: default;
}

.annotation-item:hover {
	opacity: 1.0;
	box-shadow: 0 0 5px #ffffff;
}

.annotation-main {
	display: flex;
	flex-grow: 1;
}

.annotation-label {
	display: inline-block;
	height: 100%;
	flex-grow: 1;
	user-select: none;
	-moz-user-select: none;
	z-index: 100;
	vertical-align: middle;
	line-height: 1.5em;
	font-family: Arial;
	font-weight: bold;
	cursor: pointer;
	white-space: nowrap;
}

.annotation-description {
	position: relative;
	color: white;
	background-color: black;
	padding: 10px;
	margin: 5px 0px 0px 0px;
	border-radius: 4px;
	display: none;
	max-width: 500px;
	width: 500px;
}

.annotation-description-close {
	filter: invert(100%);
	float: right;
	opacity: 0.5;
	margin: 0px 0px 8px 8px;
}


.annotation-description-content {
	color: white;
}

.annotation-icon {
	width: 20px;
	height: 20px;
	filter: invert(100%);
	margin: 2px 2px;
	opacity: 0.5;
}


canvas {
	width: 100%;
	height: 100%
}

body {
	margin: 0;
	padding: 0;
	position: absolute;
	width: 100%;
	height: 100%;
	overflow: hidden;
}

.axis {
	font: 10px sans-serif;
	color: var(--font-color);
}

.axis path {
	fill: rgba(255, 255, 255, 0.5);
	stroke: var(--font-color);
	shape-rendering: crispEdges;
	opacity: 0.7;
}

.axis line {
	fill: rgba(255, 255, 255, 0.5);
	stroke: var(--font-color);
	shape-rendering: crispEdges;
	opacity: 0.1;
}

.tick text {
	font-size: 12px;
}

.scene_header {
	display: flex;
	cursor: pointer;
	padding: 2px;
}

.scene_content {
	padding: 5px 0px 5px 0px;
	/*background-color: rgba(0, 0, 0, 0.4);*/
}

.measurement_content {
	padding: 5px 15px 5px 10px;
	/*background-color: rgba(0, 0, 0, 0.4);*/
}

.propertypanel_content {
	padding: 5px 15px 5px 10px;
	/*background-color: rgba(0, 0, 0, 0.4);*/
}

.measurement_value_table {
	width: 100%;
}

.coordinates_table_container table td {
	width: 33%;
	text-align: center;
}

#scene_object_properties {
	margin: 0px;
}




.pv-panel-heading {
	padding: 4px !important;
	display: flex;
	flex-direction: row
}

.pv-menu-list {

	list-style-type: none;
	padding: 0;
	margin: 15px 0px;
	overflow: hidden;
}

.pv-menu-list>* {
	margin: 4px 20px;
}


.ui-slider {
	margin-top: 5px !important;
	margin-bottom: 10px !important;
	background-color: var(--color-1) !important;
	
    
    height: 10px !important;
    
    
    
    border-radius: 5px !important;
}

.ui-slider::-webkit-slider-thumb {
   
    width: 20px !important;
    height: 20px !important;
    background: #4CAF50 !important;
    cursor: pointer !important;
    border-radius: 50% !important;
}

.ui-slider .ui-slider-handle {
    width: 20px !important;
    height: 20px !important;
    background-color: #03016d !important;
    border: 2px solid #03016d !important;
    cursor: pointer !important;
}


/* .ui-selectmenu-button.ui-button {
	width: 100% !important;
} */

/* .pv-menu-list>li>.ui-slider {
	/* background-color: var(--color-1) !important;
	background: none;
	border: 1px solid black;
} */

/* .pv-menu-list .ui-slider {
	/* background-color: var(--color-1) !important; 
	background: none;
	border: 1px solid black !important;
} */

/* .ui-slider-handle {
	border: 1px solid black !important;
} */

/* .ui-widget {
	box-sizing: border-box
} */

/* .panel-body>li>.ui-slider {
	background-color: var(--color-1) !important;
	background: none;
	border: 1px solid black;
} */

/* .panel-body>div>li>.ui-slider {
	background-color: var(--color-1) !important;
	background: none;
	border: 1px solid black;
} */

.pv-select-label {
	margin: 1px;
	font-size: 90%;
	font-weight: 100;
}

.button-icon:hover {
	/*background-color:	#09181C;*/
	filter: drop-shadow(0px 0px 4px white);
}

/* .ui-widget-content{
	color: var(--font-color) !important;
} */

.accordion>h3 {
	background-color: var(--bg-color-2) !important;
	background: #f6f6f6 50% 50% repeat-x;
	border: 1px solid black;
	color: var(--font-color-2);
	cursor: pointer;
	margin: 2px 0 0 0;
	padding: 4px 10px 4px 30px;
	box-shadow: 0px 3px 3px #111;
	text-shadow: 1px 1px 0 #000, -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
	font-size: 1em;
}

.accordion>h3:hover {
	filter: brightness(125%);
}

.accordion-content {
	padding: 0px 0px !important;
	border: none !important;
}

.icon-bar {
	height: 4px !important;
	border: 1px solid black;
	background-color: white;
	border-radius: 2px;
}

.canvas {
	-webkit-transition: top .35s, left .35s, bottom .35s, right .35s, width .35s;
	transition: top .35s, left .35s, bottom .35s, right .35s, width .35s;
}

#profile_window {
	background-color: var(--bg-color);
}

#profile_titlebar {
	background-color: var(--bg-color-2);
	color: var(--font-color-2);
	text-shadow: 1px 1px 0 #000, -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
	font-size: 1em;
	font-weight: bold;
}

#profile_window_title {
	position: absolute;
	margin: 5px;
}

.profile-container-button {
	cursor: pointer;
}

.profile-button:hover {
	background-color: #0000CC;
}

.unselectable {
	user-select: none;
}

.selectable {
	user-select: text;
}

.ol-dragbox {
	background-color: rgba(255, 255, 255, 0.4);
	border-color: rgba(100, 150, 0, 1);
	border: 1px solid red;
}

.text-icon {
	opacity: 0.5;
	height: 24px;
}

.text-icon:hover {
	opacity: 1.0;
}

.input-grid-cell {
	flex-grow: 1;
	margin: 0px 3px 0px 3px;
}

.input-grid-label {
	flex-grow: 1;
	margin: 0px 3px 0px 3px;
	text-align: center;
	font-weight: bold;
}

.input-grid-cell>input {
	width: 100%
}

.invalid_value {
	color: #e05e5e;
}


/** 
 * OVERRIDES
 */


.ui-spinner-input {
	color: black;
}

.jstree-themeicon-custom {
	background-size: 16px !important;
}

.jstree-default .jstree-clicked {
	/*background-color: #ffffff !important;*/
	background-color: #34494f !important;
}

.jstree-default .jstree-hovered {
	background-color: #34494f !important;
}

.jstree-anchor {
	width: 100% !important;
}

.ui-state-default {
	background: #a6a9aa !important;
	border: 1px solid black;
	color: black;
}

.ui-state-active {
	background: #c6c9ca !important;
	color: black !important;
}

.cesium-viewer .cesium-viewer-cesiumWidgetContainer {
	position: absolute;
	height: 100%;
	width: 100%;
}




.zs_widget {
	padding: 2px;
	height: 4em;
	user-select: none;
}

.zs_core {
	overflow: hidden;
	position: relative;
	height: 100%;
}

.zs_handle {
	position: absolute;
	top: 0px;
	bottom: 0px;
	border: 1px solid black;
	border-radius: 3px;
	background-color: rgb(166, 169, 170);
	width: 8px;
	user-select: none;
	width: 1.2em;
	height: 1.2em;
	top: calc(50% - 0.6em);
}

.zs_stretch {
	position: absolute;
	top: 0px;
	bottom: 0px;
	border: 1px solid black;
	border-radius: 3px;
	background-color: rgb(166, 169, 170);
	width: 8px;
	user-select: none;
	width: 1.2em;
	height: 1.2em;
	top: calc(50% - 0.6em);
	color: black;
	font-weight: bold;
	font-size: 1.2em;
	font-family: arial;
}

.zs_handle:hover {
	background-color: lightgreen;
}

.zs_inside {
	position: absolute !important;
	width: 100%;
	border: 1px solid black;
	background-color: white;
	top: calc(50% - 0.326em);
	height: 0.652em;
	cursor: zoom-in;
}

.zs_outside {
	position: absolute !important;
	width: 100%;
	background-color: var(--color-1) !important;
	top: calc(50% - 0.326em);
	height: 0.652em;
	cursor: zoom-in;
}

.zs_visible_range_label {
	position: absolute;
	bottom: 0px;
	pointer-events: none;
}

.zs_visible_range_label_left {
	left: 0px;
}

.zs_visible_range_label_right {
	right: 0px;
}

.zs_chosen_range_label {
	position: absolute;
	pointer-events: none;
}

/* #potree_sidebar_container{
	scrollbar-color: var(--color-1) var(--bg-color);
	scrollbar-width: thin;
} */



::-webkit-scrollbar {
	width: 6px;
	background-color: var(--bg-color);
}

/* ::-webkit-scrollbar-track {} */

::-webkit-scrollbar-thumb {
	background-color: var(--color-1);
}

.propertypanel_content .heading {
	font-weight: bold;
	padding-top: 0.6em;
	padding-bottom: 0.1em;
}





/* Mudanças da Drone Solucions */

.icons {
    position: absolute;
    top: 50%; /* Ajusta o topo para 50% da altura da tela */
    right: 1%;
    z-index: 1;
    background-color: #ffffff;
    border-radius: 5px;
    padding: 2px;
    transform: translateY(-50%); /* Ajusta a posição para centralizar verticalmente */
}

.icons li{
	display: flex; 
    flex-direction: column;
}

.group{
	position: absolute;
	left: 1%;
	bottom:1%;
	z-index:1;
	display: flex;
	justify-content: space-between;
	/* border: 4px solid green; */
	height: 10%;
	width: 98%;	
	gap:10px;
}

.group button{
	border-radius: 5px;
	background-color: white;
	border: 1px solid #ccc;
	padding: 10px;
	height: 100%;
}

.group button:hover{
	border-radius: 5px;
	background-color: #c2c3c4;
}


.group img{
	width: 100%;  /* Ajusta à largura do div */
  	height: 100%; /* Ajusta à altura do div */
  	object-fit: cover; /* Pode ajustar a forma de preenchimento */
}

.group-scene{
	border-radius: 5px;
	background-color: white;
	border: 1px solid #ccc;
	display: flex;
  	justify-content: center;
  	
	width: auto;
	height: auto;
	padding:10px;
} 


.group-scene:hover{
	border-radius: 5px;
	background-color: #c2c3c4;
}

.VisionGroup{
	display: flex;
	font-size: medium;
}


.aparenciaReguladores {
	display: none;
	position: absolute;
	top: 15%;
	left: 1%;
	z-index: 1;
	background-color: white;
	border-radius: 5px;
	border: 2px solid #ccc;
	width: 30%;   /* Define a largura da div */
	height: auto;  /* Define a altura da div */
	overflow-y: auto; /* Adiciona um scroll vertical quando o conteúdo exceder a altura */
	overflow-x: hidden; /* Esconde o scroll horizontal, se houver */
}

.scene_aparence {
	display: none;
	position: absolute;
	top: 15%;
	left: 1%;
	z-index: 1;
	background-color: white;
	border-radius: 5px;
	border: 2px solid #ccc;
	width: 30%;   /* Define a largura da div */
	height: 70%;  /* Define a altura da div */
	overflow-y: auto; /* Adiciona um scroll vertical quando o conteúdo exceder a altura */
	overflow-x: hidden; /* Esconde o scroll horizontal, se houver */
}

/* Responsividade: Tela menor que 530px */
@media (max-width: 530px) {
	.aparenciaReguladores{
		font-size: 3vw; /* Tamanho da letra ajustado */
	}
	.scene_aparence{
		width: 50%;
		height: 70%;
		font-size: 3vw;
	} 
}


.scene_aparence{
	text-align: center;
}

.config{
	position: absolute; 
	left: 1%; 
	top: 1%; 
	z-index: 1;
	display: flex;
	justify-content: space-between;
	height: auto;
	width: 98%;
}
.config button{
	border-radius: 5px;
	border:1px solid black;
	width: auto;
	height: auto;
	background-color: white;
	border: 1px solid #ccc;
}

.config button:hover{
	border-radius: 5px;
	background-color: #c2c3c4;
}

.config button:active{
	background-color: rgb(194, 194, 255);
}

.buttons{
	display: flex;
	gap:4px;
}

.showOptions{
	display: flex;
    flex-direction: column;
    justify-content: flex-end;
	height: auto;
	width: 50px;
	
}

.showOptions img{
	height: 100%;
	width: 100%;
	padding: 2px;
	object-fit: cover; /* Pode ajustar a forma de preenchimento */
}

.aparencia-cena-group{
	display: flex;
	gap: 5px;
}

.aparencia-cena-group img{
	height: 100%;
	width: 100%;
	object-fit: cover;
}

.aparencia{
	width: 40px;
	height: 40px;
}

.cena{
	width: 40px;
	height: 40px;
}

.date-hour {
	display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin: auto 0;
	height: 100%;
}

#vision2D{
	width: 40px;
	height: 40px;
	border-top-left-radius: 10px;
	border-bottom-left-radius: 10px;
	border-top-right-radius: 0px;
	border-bottom-right-radius: 0px;
}

#vision3D{
	width: 40px;
	height: 40px;
	border-top-left-radius: 0px;
	border-bottom-left-radius: 0px;
	border-top-right-radius: 10px;
	border-bottom-right-radius: 10px;
}

#scene_objects{
	text-align: left;
}

#scene_objects ul{
	list-style-type: none;
	text-align: left;
	padding: 0;
}
