.report-list-container {
  z-index: 1000;
}

.report-list-side-bar {
  transition: transform 0.3s ease-in-out;
}

.report-list-toast {
  position: fixed;
  top: 1.25rem;
  right: 1.25rem;
  z-index: 1007;
}
